
@font-face {
  font-family: 'NotoSans';
  src: local('Noto Sans'), local('NotoSans');
  src: url('/fonts/noto/notosans-regular.woff2') format('woff2'), url('/fonts/noto/notosans-regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  src: local('Noto Sans'), local('NotoSans');
  src: url('/fonts/noto/notosans-italic.woff2') format('woff2'), url('/fonts/noto/notosans-italic.woff') format('woff');
}

@font-face {
  font-family: 'NotoSans';
  font-weight: bold;
  src: local('Noto Sans'), local('NotoSans');
  src: url('/fonts/noto/notosans-bold.woff2') format('woff2'), url('/fonts/noto/notosans-bold.woff') format('woff');
}

@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: bold;
  src: local('Noto Sans'), local('NotoSans');
  src: url('/fonts/noto/notosans-bolditalic.woff2') format('woff2'), url('/fonts/noto/notosans-bolditalic.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansEthiopic';
  src: local('Noto Sans Ethiopic'), local('NotoSansEthiopic');
  src: url('/fonts/noto/notosansethiopic-regular.woff2') format('woff2'), url('/fonts/noto/notosansethiopic-regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansEthiopic';
  font-weight: bold;
  src: local('Noto Sans Ethiopic'), local('NotoSansEthiopic');
  src: url('/fonts/noto/notosansethiopic-bold.woff2') format('woff2'), url('/fonts/noto/notosansethiopic-bold.woff') format('woff');
}

@font-face {
  font-family: 'NotoNaskhArabic';
  src: local('Noto Naskh Arabic'), local('NotoNaskhArabic');
  src: url('/fonts/noto/notonaskharabic-regular.woff2') format('woff2'), url('/fonts/noto/notokufiarabic-regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoNaskhArabic';
  font-weight: bold;
  src: local('Noto Naskh Arabic'), local('NotoNaskhArabic');
  src: url('/fonts/noto/notonaskharabic-bold.woff2') format('woff2'), url('/fonts/noto/notonaskharabic-bold.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansDevanagari';
  src: local('Noto Sans Devanagari'), local('NotoSansDevanagari');
  src: url('/fonts/noto/notosansdevanagari-regular.woff2') format('woff2'), url('/fonts/noto/notosansdevanagari-regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansDevanagari';
  font-weight: bold;
  src: local('Noto Sans Devanagari'), local('NotoSansDevanagari');
  src: url('/fonts/noto/notosansdevanagari-bold.woff2') format('woff2'), url('/fonts/noto/notosansdevanagari-bold.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansMyanmar';
  src: local('Noto Sans Myanmar'), local('NotoSansMyanmar');
  src: url('/fonts/noto/notosansmyanmar-regular.woff2') format('woff2'), url('/fonts/noto/notosansmyanmar-regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansMyanmar';
  font-weight: bold;
  src: local('Noto Sans Myanmar'), local('NotoSansMyanmar');
  src: url('/fonts/noto/notosansmyanmar-bold.woff2') format('woff2'), url('/fonts/noto/notosansmyanmar-bold.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansKhmer';
  src: local('Noto Sans Khmer'), local('NotoSansKhmer');
  src: url('/fonts/noto/notosanskhmer-regular.woff2') format('woff2'), url('/fonts/noto/notosanskhmer-regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansKhmer';
  font-weight: bold;
  src: local('Noto Sans Khmer'), local('NotoSansKhmer');
  src: url('/fonts/noto/notosanskhmer-bold.woff2') format('woff2'), url('/fonts/noto/notosanskhmer-bold.woff') format('woff');
}

// Noto Sans appears to cover all Latin, Greek and Cyrillic writing systems
* {
  font-family: NotoSans, sans-serif;
  font-style: normal;
  font-weight: 400;
}

// Noto Sans Ethiopic supports these languages
:lang(am),  // Amharic
:lang(gez), // Geez
:lang(ti),  // Tigrinya
:lang(tir), // Tigrinya
:lang(tig)  // Tigre
{
  font-family: NotoSans, NotoSansEthiopic, sans-serif;
  word-spacing: .02em;
}

// remove bold styling from heading for Ethiopic languages, per SIL example of this font's usage
:lang(am) h1,
:lang(gez) h1,
:lang(ti) h1,
:lang(tir) h1,
:lang(tig) h1
{
  font-weight: normal;
}

// Arabic script
:lang(fa) { font-family: NotoSans, NotoNaskhArabic, sans-serif; }

// Devanagari script
:lang(sgj) { font-family: NotoSans, NotoSansDevanagari, sans-serif; }

// Burmese script
:lang(my) { font-family: NotoSans, NotoSansMyanmar, sans-serif; }

// Khmer script
:lang(km) { font-family: NotoSans, NotoSansKhmer, sans-serif; }
